<template>
	<div>
		<div class="wfu_title">
			紧急：客户逾期未回款 2单；供应商逾期未发货3单</div>
		<div class="wfu_div">
			<div class="wfu_div_left">
				<div class="wfu_div_le_contentDiv">
					<div class="wfu_div_le_cd_title">待分配销售</div><el-link type="primary"
						class="wfu_div_le_cd_ti_text">88单</el-link>
				</div>
				<div class="wfu_div_le_contentDiv">
					<div class="wfu_div_le_cd_title">待分配采购</div><el-link type="primary"
						class="wfu_div_le_cd_ti_text">88单</el-link>
				</div>
				<div class="wfu_div_le_contentDiv">
					<div class="wfu_div_le_cd_title">待采购报价</div><el-link type="primary"
						class="wfu_div_le_cd_ti_text">88单</el-link>
				</div>
				<div class="wfu_div_le_contentDiv">
					<div class="wfu_div_le_cd_title">待销售报价</div><el-link type="primary"
						class="wfu_div_le_cd_ti_text">88单</el-link>
				</div>
				<div class="wfu_div_le_contentDiv">
					<div class="wfu_div_le_cd_title">待销售下单</div><el-link type="primary"
						class="wfu_div_le_cd_ti_text">88单</el-link>
				</div>
				<div class="wfu_div_le_contentDiv">
					<div class="wfu_div_le_cd_title">待合同审核</div><el-link type="primary"
						class="wfu_div_le_cd_ti_text">88单</el-link>
				</div>
				<div class="wfu_div_le_contentDiv">
					<div class="wfu_div_le_cd_title">待采购下单</div><el-link type="primary"
						class="wfu_div_le_cd_ti_text">88单</el-link>
				</div>
				<div class="wfu_div_le_contentDiv">
					<div class="wfu_div_le_cd_title">待采购审核</div><el-link type="primary"
						class="wfu_div_le_cd_ti_text">88单</el-link>
				</div>
				<div class="wfu_div_le_contentDiv">
					<div class="wfu_div_le_cd_title">待入库</div><el-link type="primary"
						class="wfu_div_le_cd_ti_text">88单</el-link>
				</div>
				<div class="wfu_div_le_contentDiv">
					<div class="wfu_div_le_cd_title">待出库</div><el-link type="primary"
						class="wfu_div_le_cd_ti_text">88单</el-link>
				</div>
				<div class="wfu_div_le_contentDiv">
					<div class="wfu_div_le_cd_title">待确认完成</div><el-link type="primary"
						class="wfu_div_le_cd_ti_text">88单</el-link>
				</div>
			</div>
		
			<div class="wfu_div_right">
				<div v-infinite-scroll="handleInfiniteOnLoad" class="demo-infinite-container"
					:infinite-scroll-disabled="busy" :infinite-scroll-distance="10">
					<a-list :data-source="data">
						<a-list-item slot="renderItem" slot-scope="item, index">
							<a-list-item-meta>
								<a slot="title" style="width: 100%;display: flex;">{{ item }}</a>
							</a-list-item-meta>
						</a-list-item>
						<a-spin v-if="loading && !busy" class="demo-loading-container" />
					</a-list>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import reqwest from 'reqwest';
	import infiniteScroll from 'vue-infinite-scroll';
	// import homeApi from '@/api/home/index'
	import {getPersonTaskList} from '@/api/home/index.js'
	import Cookies from "js-cookie";
	export default {
		directives: {
			infiniteScroll
		},
		data() {
			return {
				data: ['订单号A00001111 08/11 09:35 采购已报','订单号A00001111 08/11 09:35 采购已报','订单号A00001111 08/11 09:35 采购已报','订单号A00001111 08/11 09:35 采购已报','订单号A00001111 08/11 09:35 采购已报','订单号A00001111 08/11 09:35 采购已报','订单号A00001111 08/11 09:35 采购已报','订单号A00001111 08/11 09:35 采购已报'],
				loading: false,
				busy: false,
				userInfo: null,
			}
		},
		
		created() {
			let userInfo = Cookies.get("userInfo")
			if (userInfo) {
				userInfo = JSON.parse(userInfo)
				this.userInfo = userInfo
			}
			this.getPersonTaskList();
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			handleInfiniteOnLoad() {
				const data = this.data;
				this.loading = true;
				if (data.length > 14) {
					this.busy = true;
					this.loading = false;
					return;
				}
				this.loading = false;
			},
			
			getPersonTaskList:function(){
				let data = {
					userCode:this.userInfo.usercode
				}
				getPersonTaskList(data).then(res=>{
					
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.wfu_title{
		padding: 0 20px 10px 20px;
		color:red;
		font-weight: bold;
		font-size: 0.7vw;
		display: flex;
		flex-wrap: wrap;
	}
	
	.wfu_div{
		display: flex;
		padding:0 20px 20px 20px;
		.wfu_div_left{
			border: 1px solid #efefef;
			width: 30%;
			padding-bottom: 10px;
			height: 370px;
			
			.wfu_div_le_contentDiv{
				position: relative;
				width: 100%;
				padding-left:10px;
				display: flex;
				flex-wrap: wrap;
				padding:10px 10px 0 10px;
				
				.wfu_div_le_cd_title{
					font-size: 20rpx;
					
					.wfu_div_le_cd_ti_text{
						font-size: 20rpx;
						margin-left: 10px;
					}
				}
			}
		}
		
		.wfu_div_right{
			width: 70%;
			margin-left: 10px;
			border: 1px solid #efefef;
		}
	}
	
	.demo-infinite-container {
		// border: 1px solid #e8e8e8;
		width: 100%;
		overflow: auto;
		padding: 8px 24px;
		height: 370px;
	}
	
	.demo-loading-container {
		text-align: center;
		  border-radius: 4px;
		  margin-bottom: 20px;
		  padding: 30px 50px;
		  margin: 20px 0;
		  height: 370px;
	}
</style>