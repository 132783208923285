<template>
	<div>
		<div class="informationMining" :style="windowWidth>=1200 ? 'display:flex;' : ''">
			<div class="im_div demo-infinite-container2" v-infinite-scroll="handleInfiniteOnLoad"
					:infinite-scroll-disabled="busy" :infinite-scroll-distance="10" :style="windowWidth>=1200 ? 'width:20%;' : 'width:100%;'">
				<div class="im_div_content">
					<div class="im_div_ct_title">新客户(本月刚有交易)</div><el-link type="primary"
						class="im_div_ct_ti_text">30家</el-link>
				</div>
				<div class="im_div_content">
					<div class="im_div_ct_title">活跃客户(本月内有交易)</div><el-link type="primary"
						class="im_div_ct_ti_text">30家</el-link>
				</div>
				<div class="im_div_content">
					<div class="im_div_ct_title">潜力客户(本月内有询价)</div><el-link type="primary"
						class="im_div_ct_ti_text">30家</el-link>
				</div>
				<div class="im_div_content">
					<div class="im_div_ct_title">待唤醒客户(上月有交易)</div><el-link type="primary"
						class="im_div_ct_ti_text">30家</el-link>
				</div>
				<div class="im_div_content">
					<div class="im_div_ct_title">沉睡客户(三月有交易)</div><el-link type="primary"
						class="im_div_ct_ti_text">30家</el-link>
				</div>
				<div class="im_div_content">
					<div class="im_div_ct_title">快流失客户(半年内无交易)</div><el-link type="primary"
						class="im_div_ct_ti_text">30家</el-link>
				</div>
				<div class="im_div_content">
					<div class="im_div_ct_title">已流失客户 (超半年无交易)</div><el-link type="primary"
						class="im_div_ct_ti_text">30家</el-link>
				</div>
			</div>
			
			<div class="im_conter" :style="windowWidth>=1200 ? 'width: 30%;' : 'width:100%;'">
				<div v-infinite-scroll="handleInfiniteOnLoad" class="demo-infinite-container2"
					:infinite-scroll-disabled="busy" :infinite-scroll-distance="10">
					<a-list :data-source="data">
						<a-list-item slot="renderItem" slot-scope="item, index">
							<a-list-item-meta>
								<a slot="title" style="width: 100%;display: flex;">{{ item }}</a>
							</a-list-item-meta>
						</a-list-item>
						<a-spin v-if="loading && !busy" class="demo-loading-container" />
					</a-list>
				</div>
			</div>
			
			<div class="echartsMain" :style="windowWidth>=1200 ? 'width: 50%;height: 300px;' : 'width:100%;height:300px;'" id="main3"></div>
		</div>
	</div>
</template>

<script>
	import macarons from '@/assets/echartsTheme/macarons.json'
	export default {
		data() {
			return {
				data: ['订单号A00001111 08/11 09:35 采购已报','订单号A00001111 08/11 09:35 采购已报','订单号A00001111 08/11 09:35 采购已报','订单号A00001111 08/11 09:35 采购已报','订单号A00001111 08/11 09:35 采购已报','订单号A00001111 08/11 09:35 采购已报','订单号A00001111 08/11 09:35 采购已报','订单号A00001111 08/11 09:35 采购已报'],
				loading: false,
				busy: false,
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
			}
		},
		
		created() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
		},
		
		mounted() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
			this.drawChart();
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			handleInfiniteOnLoad() {
				const data = this.data;
				this.loading = true;
				if (data.length > 14) {
					this.busy = true;
					this.loading = false;
					return;
				}
				this.loading = false;
			},
			
			drawChart() {
				this.$echarts.registerTheme('macarons', macarons)
				var chartDom = document.getElementById('main3');
				var myChart = this.$echarts.init(chartDom,'macarons');
				var option;
				
				option = {
				  tooltip: {
				    trigger: 'axis',
				    axisPointer: {
				      type: 'cross',
				      crossStyle: {
				        color: '#999'
				      }
				    }
				  },
				  toolbox: {
				    feature: {
				      dataView: { show: true, readOnly: false },
				      magicType: { show: true, type: ['line', 'bar'] },
				      restore: { show: true },
				      saveAsImage: { show: true }
				    }
				  },
				  legend: {
				    data: ['Evaporation', 'Precipitation', 'Temperature']
				  },
				  xAxis: [
				    {
				      type: 'category',
				      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
				      axisPointer: {
				        type: 'shadow'
				      }
				    }
				  ],
				  yAxis: [
				    {
				      type: 'value',
				      name: 'Precipitation',
				      min: 0,
				      max: 250,
				      interval: 50,
				      axisLabel: {
				        formatter: '{value} ml'
				      }
				    },
				    {
				      type: 'value',
				      name: 'Temperature',
				      min: 0,
				      max: 25,
				      interval: 5,
				      axisLabel: {
				        formatter: '{value} °C'
				      }
				    }
				  ],
				  series: [
				    {
				      name: 'Evaporation',
				      type: 'bar',
				      tooltip: {
				        valueFormatter: function (value) {
				          return value + ' ml';
				        }
				      },
				      data: [
				        2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
				      ]
				    },
				    {
				      name: 'Precipitation',
				      type: 'bar',
				      tooltip: {
				        valueFormatter: function (value) {
				          return value + ' ml';
				        }
				      },
				      data: [
				        2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
				      ]
				    },
				    {
				      name: 'Temperature',
				      type: 'line',
				      yAxisIndex: 1,
				      tooltip: {
				        valueFormatter: function (value) {
				          return value + ' °C';
				        }
				      },
				      data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
				    }
				  ]
				};
				
				option && myChart.setOption(option);
				window.addEventListener('resize', function () {
				  myChart.resize();
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.informationMining{
		width:100%;
		padding: 0 20px 20px 20px;
		
		.im_div{
			border: 1px solid #efefef;
			padding:10px;
			height: 300px;
			margin-right: 20px;
			
			.im_div_content{
				position: relative;
				width: 100%;
				padding-left:10px;
				display: flex;
				flex-wrap: wrap;
				padding:10px 10px 0 10px;
				
				.im_div_ct_title{
					font-size: 16px;
					
					.im_div_ct_ti_text{
						font-size: 16px;
						margin-left: 10px;
					}
				}
			}
		}
		
		.im_conter{
			display: flex;
			border: 1px solid #efefef;
			padding:10px;
			height: 300px;
			margin-right: 20px;
		}
	}
	
	.demo-infinite-container2 {
		// border: 1px solid #e8e8e8;
		width: 100%;
		overflow: auto;
		padding: 8px 24px;
		height: 275px;
	}
	
	.demo-loading-container {
		text-align: center;
		  border-radius: 4px;
		  margin-bottom: 20px;
		  padding: 30px 50px;
		  margin: 20px 0;
		  height: 370px;
	}
	
	.echartsMain{
		display: flex;
		border: 1px solid #efefef;
		padding:10px;
	}
</style>