<template>
	<div>
		<div class="fdl_title">
			上月销冠 张富贵。加油哦，本月的销冠可能就是你!</div>
		<div class="fdl_contentDiv">
			<div class="fdl_cd_table">
				<div class="fdl_cd_tb_text" style="height: 33px;"></div>
				<div class="fdl_cd_tb_text">公司总业绩</div>
				<div class="fdl_cd_tb_text">我的业绩</div>
				<div class="fdl_cd_tb_text">占比</div>
				<div class="fdl_cd_tb_text">排名</div>
			</div>
			<div class="fdl_cd_table">
				<div class="fdl_cd_tb_text">收入</div>
				<div class="fdl_cd_tb_text">200W</div>
				<div class="fdl_cd_tb_text">10w</div>
				<div class="fdl_cd_tb_text">5%</div>
				<div class="fdl_cd_tb_text">10</div>
			</div>
			<div class="fdl_cd_table">
				<div class="fdl_cd_tb_text">处理客户询价</div>
				<div class="fdl_cd_tb_text">200W</div>
				<div class="fdl_cd_tb_text">10w</div>
				<div class="fdl_cd_tb_text">5%</div>
				<div class="fdl_cd_tb_text">10</div>
			</div>
			<div class="fdl_cd_table">
				<div class="fdl_cd_tb_text">已审核订单</div>
				<div class="fdl_cd_tb_text">200W</div>
				<div class="fdl_cd_tb_text">10w</div>
				<div class="fdl_cd_tb_text">5%</div>
				<div class="fdl_cd_tb_text">10</div>
			</div>
			<div class="fdl_cd_table">
				<div class="fdl_cd_tb_text">电话时长</div>
				<div class="fdl_cd_tb_text">200W</div>
				<div class="fdl_cd_tb_text">10w</div>
				<div class="fdl_cd_tb_text">5%</div>
				<div class="fdl_cd_tb_text">10</div>
			</div>
			<div class="fdl_cd_table">
				<div class="fdl_cd_tb_text">拜访客户</div>
				<div class="fdl_cd_tb_text">200W</div>
				<div class="fdl_cd_tb_text">10w</div>
				<div class="fdl_cd_tb_text">5%</div>
				<div class="fdl_cd_tb_text">10</div>
			</div>
			<div class="fdl_cd_table">
				<div class="fdl_cd_tb_text">已回款</div>
				<div class="fdl_cd_tb_text">200W</div>
				<div class="fdl_cd_tb_text">10w</div>
				<div class="fdl_cd_tb_text">5%</div>
				<div class="fdl_cd_tb_text">10</div>
			</div>
			<div class="fdl_cd_table">
				<div class="fdl_cd_tb_text">应收款</div>
				<div class="fdl_cd_tb_text">200W</div>
				<div class="fdl_cd_tb_text">10w</div>
				<div class="fdl_cd_tb_text">5%</div>
				<div class="fdl_cd_tb_text">10</div>
			</div>
			<div class="fdl_cd_table">
				<div class="fdl_cd_tb_text">季度毛利</div>
				<div class="fdl_cd_tb_text">200W</div>
				<div class="fdl_cd_tb_text">10w</div>
				<div class="fdl_cd_tb_text">5%</div>
				<div class="fdl_cd_tb_text">10</div>
			</div>
			<div style="padding-top: 10px;">
				我的收入：
				<div>本月本人累计已回款800w，毛利100w，已提成80w，待提成20w本组季度累计订单毛利100w，待奖金15000</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	.fdl_title{
		padding: 0 20px 10px 20px;
		color:red;
		font-weight: bold;
		font-size: 0.7vw;
		display: flex;
		flex-wrap: wrap;
	}
	
	.fdl_contentDiv{
		padding: 0 20px 10px 20px;
		height: 390px;
		
		.fdl_cd_table{
			width: 100%;
			display: flex;
			flex-wrap:wrap;
			align-items: center;
			
			.fdl_cd_tb_text{
				border: 1px solid #e8e8e8;
				padding: 5px;
				text-align: center;
				width: 20%;
			}
		}
	}
</style>