<template>
	<div style="">
		<div style="display:flex;">
			<div style="font-size: 30px;font-weight: bold;">工作台</div>
		</div>
		<div style="display: flex;align-items: center;padding-bottom:20px;">
			<div style="padding-right:10px;">常用快捷指令</div>
			<el-button type="text" @click="jump(0)">创建新客户</el-button>
			<el-button type="text" @click="jump(1)">代客户下单</el-button>
		</div>
		<a-row>
			<a-col :span="windowWidth>=1200 ? 12 : 24">
				<div class="consultant">
					<a-page-header :ghost="false" title="今日工作跟进">
						<template slot="extra">
							<a-icon type="more" />
						</template>
					</a-page-header>
					<work-follow-up></work-follow-up>
				</div>
			</a-col>
			
			<a-col :span="windowWidth<=1199 ? 24 : 12">
				<div class="consultant">
					<a-page-header :ghost="false" title="财务台日榜(TAB页)">
						<template slot="extra">
							<a-icon type="more" />
						</template>
					</a-page-header>
					<financial-daily-list></financial-daily-list>
				</div>
			</a-col>
			
			<a-col :span="24" v-if="windowWidth>=1200">
				<div class="consultant">
					<a-page-header :ghost="false" title="我的业绩 (主管看的是组里每个人员的)">
						<template slot="extra">
							<a-icon type="more" />
						</template>
					</a-page-header>
					<div style="display:flex;width:100%;">
						<monthly-performance style="width: 50%;"></monthly-performance>
						<annual-performance style="width: 50%;"></annual-performance>
					</div>
				</div>
			</a-col>
			<a-col :span="24" v-if="windowWidth<=1199">
				<div class="consultant">
					<a-page-header :ghost="false" title="我的业绩 (主管看的是组里每个人员的)">
						<template slot="extra">
							<a-icon type="more" />
						</template>
					</a-page-header>
					<div style="width:100%;">
						<monthly-performance style="width: 100%;"></monthly-performance>
						<annual-performance style="width: 100%;"></annual-performance>
					</div>
				</div>
			</a-col>
			
			<a-col :span="24">
				<div class="consultant">
					<a-page-header :ghost="false" title="大数据信息挖掘 (CRM)">
						<template slot="extra">
							<a-icon type="more" />
						</template>
					</a-page-header>
					<information-mining></information-mining>
				</div>
			</a-col>
			
			<a-col :span="24">
				<div class="consultant">
					<a-page-header :ghost="false" title="大数据信息挖掘 (CRM)">
						<template slot="extra">
							<a-icon type="more" />
						</template>
					</a-page-header>
					<customer-gross-profit></customer-gross-profit>
				</div>
			</a-col>
		</a-row>
	</div>
</template>

<script>
	import Cookies from "js-cookie";
	import reqwest from 'reqwest';
	import infiniteScroll from 'vue-infinite-scroll';
	import macarons from '@/assets/echartsTheme/macarons.json'
	
	import workFollowUp from '@/business/views/erp/home/components/WorkFollowUp.vue'
	import financialDailyList from '@/business/views/erp/home/components/financialDailyList.vue'
	import monthlyPerformance from '@/business/views/erp/home/components/monthlyPerformance.vue'
	import annualPerformance from '@/business/views/erp/home/components/annualPerformance.vue'
	import informationMining from '@/business/views/erp/home/components/informationMining.vue'
	import customerGrossProfit from '@/business/views/erp/home/components/customerGrossProfit.vue'
	export default {
		directives: {
			infiniteScroll
		},
		components:{
			workFollowUp,
			financialDailyList,
			monthlyPerformance,
			annualPerformance,
			informationMining,
			customerGrossProfit
		},
		data() {
			return {
				userInfo: null, //用户信息
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
			}
		},

		created() {
			this.userInfo = Cookies.get("userInfo")
			if (this.userInfo) {
				this.userInfo = JSON.parse(this.userInfo)
			}
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
		},

		mounted() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
		},

		watch: {

		},

		computed: {

		},

		methods: {
			//----------------常用快捷指令----------------------------
			jump:function(e){
				if(e==0){
					this.$router.push({
						path: '/erp/customerManage/customer'
					})
				}else{
					this.$router.push({
						path: '/erp/customerManage/user'
					})
				}
			}
		}
	}
</script>

<style lang="less" scoped>

	.consultant {
		background: #fff;
		margin-right: 20px;
		margin-bottom:20px;

		.cl_div {
			padding: 20px;

			.cl_di_headDiv {
				text-align: center;

				.cl_di_hd_img {
					width: 4vw;
					height: 4vw;
					border-radius: 50%;
				}

				.cl_di_hd_name {
					text-align: center;
					padding-top: 20px;
				}
			}


			.cl_di_conatentDiv {
				padding-top: 20px;
				font-size: 16px;

				.cl_di_cd_name {}
			}
		}
	}
</style>