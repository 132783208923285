<template>
	<div>
		<div id="main1" style="height: 500px"></div>
	</div>
</template>

<script>
	import macarons from '@/assets/echartsTheme/macarons.json'
	export default {
		data(){
			return {
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			this.drawChart();
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			drawChart() {
				this.$echarts.registerTheme('macarons', macarons)
				var chartDom = document.getElementById('main1');
				var myChart = this.$echarts.init(chartDom,'macarons');
				var option;
				
				option = {
				  tooltip: {
				    trigger: 'axis',
				    axisPointer: {
				      type: 'cross',
				      crossStyle: {
				        color: '#999'
				      }
				    }
				  },
				  toolbox: {
				    feature: {
				      dataView: { show: true, readOnly: false },
				      magicType: { show: true, type: ['line', 'bar'] },
				      restore: { show: true },
				      saveAsImage: { show: true }
				    }
				  },
				  legend: {
				    data: ['Evaporation', 'Precipitation', 'Temperature']
				  },
				  xAxis: [
				    {
				      type: 'category',
				      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
				      axisPointer: {
				        type: 'shadow'
				      }
				    }
				  ],
				  yAxis: [
				    {
				      type: 'value',
				      name: 'Precipitation',
				      min: 0,
				      max: 250,
				      interval: 50,
				      axisLabel: {
				        formatter: '{value} ml'
				      }
				    },
				    {
				      type: 'value',
				      name: 'Temperature',
				      min: 0,
				      max: 25,
				      interval: 5,
				      axisLabel: {
				        formatter: '{value} °C'
				      }
				    }
				  ],
				  series: [
				    {
				      name: 'Evaporation',
				      type: 'bar',
				      tooltip: {
				        valueFormatter: function (value) {
				          return value + ' ml';
				        }
				      },
				      data: [
				        2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
				      ]
				    },
				    {
				      name: 'Precipitation',
				      type: 'bar',
				      tooltip: {
				        valueFormatter: function (value) {
				          return value + ' ml';
				        }
				      },
				      data: [
				        2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
				      ]
				    },
				    {
				      name: 'Temperature',
				      type: 'line',
				      yAxisIndex: 1,
				      tooltip: {
				        valueFormatter: function (value) {
				          return value + ' °C';
				        }
				      },
				      data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
				    }
				  ]
				};
				
				option && myChart.setOption(option);
				// 为窗口加上宽度变化事件，当宽高发生改变时，调用echarts的resize()方法，调整图表尺寸
				  window.addEventListener('resize', function () {
				    myChart.resize();
				  })
			},
		}
	}
</script>

<style lang="less" scoped>
	
</style>